// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-help-center-js": () => import("./../../../src/pages/portfolio/help-center.js" /* webpackChunkName: "component---src-pages-portfolio-help-center-js" */),
  "component---src-pages-portfolio-help-content-js": () => import("./../../../src/pages/portfolio/help-content.js" /* webpackChunkName: "component---src-pages-portfolio-help-content-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-knowledge-base-js": () => import("./../../../src/pages/portfolio/knowledge-base.js" /* webpackChunkName: "component---src-pages-portfolio-knowledge-base-js" */),
  "component---src-pages-portfolio-microcopy-js": () => import("./../../../src/pages/portfolio/microcopy.js" /* webpackChunkName: "component---src-pages-portfolio-microcopy-js" */),
  "component---src-pages-portfolio-new-user-onboarding-js": () => import("./../../../src/pages/portfolio/new-user-onboarding.js" /* webpackChunkName: "component---src-pages-portfolio-new-user-onboarding-js" */),
  "component---src-pages-portfolio-style-guides-templates-workflows-js": () => import("./../../../src/pages/portfolio/style-guides-templates-workflows.js" /* webpackChunkName: "component---src-pages-portfolio-style-guides-templates-workflows-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-template-js": () => import("./../../../src/pages/template.js" /* webpackChunkName: "component---src-pages-template-js" */)
}

